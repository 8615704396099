<template>
  <div>
    <topHeader :userData="user" />
    <div class="mentorship">
      <div class="mentorship__mentbgimg __mentorimg">
        <b-container>
          <p class="text-center mentorship__bookmen">Book a session with me</p>
        </b-container>
      </div>

      <b-container>
        <b-row>
          <b-col cols="12" xl="1" lg="1" md="12" sm="1"></b-col>
          <b-col cols="12" xl="10" lg="10" md="12" sm="10">
            <div class="mentorship__cards">
              <b-row>
                <BookingSectionLeftBar />

                <b-col cols="12" xl="7" lg="7" md="6" sm="6">
                  <div class="mentorship__cards-card1"> 
                    <p class="mentorship__cards-mentp">Mentorship</p>
                    <div class="form-group">
                      <label for="" class="form__formlabel mentorship__label">
                        Duration
                      </label>
                      <input
                        type="text"
                        class="form-control form__formedit"
                        :value="duration"
                        disabled
                      />
                    </div>
                    <p class="form__formlabel mentorship__label">Select Date</p>

                    <div class="calendar-container" v-if="date">
                      <v-date-picker
                        mode="date"
                        v-model="date"
                        :available-dates="availableDates"
                        title-position="left"
                      />
                    </div>

                    <p class="form__formlabel mentorship__label">
                      Available Time
                    </p>
                    <div class="mentorship__avtime">
                      <template v-for="(time, index) of times">
                        <button
                          type="button"
                          class="btn mentorship__avtime-times"
                          :key="index"
                          @click.prevent="setBookingTime(time, index)"
                          :class="[{ 'selected-time': index === selectedTime }]"
                        >
                          {{ time }}
                        </button>
                      </template>
                    </div>

                    <b-row>
                      <b-col cols="2" xl="8" lg="8" md="7" sm="7"></b-col>
                      <b-col
                        cols="10"
                        xl="4"
                        lg="4"
                        md="5"
                        sm="5"
                        class="professional__shifr"
                      >
                        <button
                          @click="isShowing = !isShowing"
                          v-if="!isShowing"
                          class="btn mentorship__btnsave"
                        >
                          Proceed
                        </button>
                      </b-col>
                    </b-row>

                    <div
                      class="mentorship__cards-card1-showproceed"
                      v-if="isShowing"
                    >
                      <div class="__spacet5p"></div>
                      <p class="mentorship__cards-mentp">Enter Details</p>

                      <form class="form">
                        <div class="form-group">
                          <label for="" class="form__formlabel">Name</label>
                          <input
                            type="text"
                            class="form-control form__formedit"
                            placeholder="Lovely Ajayi"
                            v-model="fullName"
                          />
                        </div>

                        <div class="form-group">
                          <label for="" class="form__formlabel">Email</label>
                          <input
                            type="email"
                            class="form-control form__formedit"
                            placeholder="Lovely@email.com"
                            v-model="email"
                          />
                        </div>

                        <div class="form-group">
                          <label for="" class="form__formlabel">
                            What do you want to learn for this session?
                          </label>
                          <textarea
                            class="form-control form__formedit"
                            placeholder=""
                            rows="8"
                            v-model="comment"
                          ></textarea>
                        </div>

                        <b-row>
                          <b-col cols="2" xl="6" lg="6" md="4" sm="4"></b-col>
                          <b-col
                            cols="10"
                            xl="6"
                            lg="6"
                            md="8"
                            sm="8"
                            class="professional__shifr"
                          >
                            <a
                              href=""
                              class="btn mentorship__btnsave d-flex"
                              @click.prevent="handleScheduleEvent"
                            >
                              Schedule Events
                              <Loader :shouldShow="isBookingSession" />
                            </a>
                          </b-col>
                        </b-row>
                      </form>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" xl="1" lg="1" md="12" sm="1"></b-col>
        </b-row>
      </b-container>
    </div>
    <div class="__spacet7p"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";

import topHeader from "../components/topHeader";
import BookingSectionLeftBar from "./common/BookingSectionLeftBar";
import Loader from "../components/loader";
import UserMixin from "../mixins/usersMixins";

import { errorHandler } from "../../utils/helpers";

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const minDate = new Date(today);
minDate.setMonth(minDate.getMonth() - 2);
minDate.setDate(15);
const maxDate = new Date(today);
maxDate.setMonth(maxDate.getMonth() + 2);
maxDate.setDate(15);

export default {
  mixins: [UserMixin],
  computed: mapGetters([
    "mentorshipSessions",
    "selectedMentorshipSession",
    "user"
  ]),
  components: {
    topHeader,
    BookingSectionLeftBar,
    Loader,
  },
  data() {
    return {
      min: minDate,
      max: maxDate,
      selected_date: "",
      selected_date_2: "",
      context: null,
      isShowing: false,
      session: null,
      date: null,
      availableDates: { start: null, end: null },
      duration: "",
      times: [],
      bookingTime: null,
      fullName: "",
      email: "",
      comment: "",
      selectedTime: null,
      isBookingSession: false,
    };
  },
  methods: {
    ...mapActions([
      "fetchSessions",
      "fetchASession",
      "bookSession",
      "handleFetchCompany"
    ]),
    setBookingTime(time, index) {
      this.bookingTime = time;
      this.selectedTime = index;
    },
    async handleScheduleEvent() {
      try {
        const {userSlug} = this.$route.params;
        this.isBookingSession = true;
        
        localStorage.setItem('user_profile_url', `${userSlug}`);

        if (!this.fullName && !this.email && !this.bookingTime)  
          throw  { message: "Name, Email and booking time is required" };

        const result = await this.bookSession({
          schedule_id: this.session.schedule.id,
          mentorship_id: this.session.id,
          time: this.bookingTime,
          name: this.fullName,
          email: this.email,
          slug: this.$route.params.userSlug,
        });


        if (result.errors && result.errors.length > 0) throw result.errors[0]

        this.bookingTime = null;
        this.fullName = "";
        this.email = "";
        this.comment = "";
        this.isShowing = false;

        this.isBookingSession = false;
        this.$toast.success(`${result.message}`);

        if(this.session.payment_mode &&  this.session.payment_mode.toLowerCase() === 'free') {
          return this.$router.push(`/${localStorage.getItem('user_profile_url')}`);
        }

        location.href = `${result.data.payment.config.authorization_url}`;
        
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.isBookingSession = false;
      }
    },
  },
  mounted() {
    $("button[type='button']").click(function () {
      $("button[type='button'].colorbg").removeClass("colorbg");
      $(this).addClass("colorbg");
    });
  },
  async created() {
    const { userSlug, sessionSlug } = this.$route.params;
    this.session = this.selectedMentorshipSession;

    localStorage.setItem('user_profile_url', `/${userSlug}/profile`);
    const userType = localStorage.getItem("book_me-logged-in-user-type");
    const loggedInUserSlug = localStorage.getItem("book_me-logged-in-user-slug");

    try {
      if (!this.user || !this.session) {
        const { data } = await this.fetchASession({
          userSlug,
          sessionSlug: sessionSlug ? sessionSlug : this.session.slug,
        });
        await this.fetchSessions(`${userSlug}`);
        this.session = data;
      }

      if(this.session) {
        this.date = new Date(this.session.schedule.date);
        this.availableDates = { start: this.date, end: this.date };
        this.duration = this.session.duration;
        this.times = this.session.schedule.times.split(",");
      }
      
      if(!this.user && userType === 'company') {
        await this.handleFetchCompany(`${loggedInUserSlug}`);
      }

      this.email = this.user.email;
      this.fullName = this.user.fullName;

    } catch (error) {
      errorHandler(error, this.$toast);
    }
  },
};
</script>